import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

export const SSumaries = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${size(6)};
  padding-top: ${size(4)};

  @media ${media.gt('mobile')} {
    flex-wrap: nowrap;
    margin: 0 auto;
    width: 100%;
  }

  @media ${media.gt('tablet')} {
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
  }
`
