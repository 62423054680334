import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius } from 'driverama-core/styles/variables'

export const ReviewsSummaryWrapper = styled.div`
  align-items: center;
  background-color: ${color('white')};
  border: 2px solid ${color('night-l-800')};
  border-radius: ${radius('corner-mid')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: ${size(50)};
  padding: 0 ${size(3)};
  margin-right: 0;
  margin-top: ${size(6)};

  @media ${media.gt('SM')} {
    margin-right: ${size(4)};
  }

  @media ${media.gt('tablet')} {
    max-width: ${size(45)};
  }
`
