import {
  TRUSTPILOT_BUSINESS_UNIT_ID,
  useTrustpilotLocale
} from 'components/trustpilot/Trustpilot.utils'
import { useEffect, useRef } from 'react'

const TEMPLATE_ID = '53aa8807dec7e10d38f59f32'

// https://businessapp.b2b.trustpilot.com/trustbox/53aa8807dec7e10d38f59f32
export function TrustpilotMiniWidget({
  height,
  theme
}: {
  height?: string
  theme?: 'light' | 'dark'
}) {
  const { locale, link } = useTrustpilotLocale()

  const ref = useRef(null)

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <div
      className="trustpilot-widget"
      data-locale={locale()}
      data-template-id={TEMPLATE_ID}
      data-businessunit-id={TRUSTPILOT_BUSINESS_UNIT_ID}
      data-style-height={height ?? '100px'}
      data-style-width="100%"
      data-theme={theme || 'light'}
      ref={ref}
    >
      <a href={link()} target="_blank" rel="noopener noreferrer" />
    </div>
  )
}
