import { css } from '@emotion/react'
import { UserReviewFragment } from 'api/contentful/generated/contentful'
import { UserReviewsCarouselList } from 'components/userReview/carousel/list/UserReviewsCarouselList'
import { media } from 'driverama-core/styles/media'
import { ReactNode } from 'react'

type Props = {
  reviews: UserReviewFragment[]
  reverse?: boolean
  title?: ReactNode
  subtitle?: ReactNode
  description?: ReactNode
}

export function UserReviews({ reverse, reviews, ...props }: Props) {
  return (
    <>
      <span
        css={css`
          @media ${media.gt('tablet')} {
            display: ${reverse ? 'none' : 'block'};
          }
        `}
      >
        <UserReviewsCarouselList {...props} reviews={reviews} />
      </span>

      {reverse && (
        <span
          css={css`
            @media ${media.lte('tablet')} {
              display: none;
            }
          `}
        >
          <UserReviewsCarouselList {...props} reviews={reviews} reverse />
        </span>
      )}
    </>
  )
}
