import styled from '@emotion/styled'
import { invisibleScrollbars } from 'driverama-core/styles/common'
import { size } from 'driverama-core/styles/spacing'
import { media } from 'driverama-core/styles/media'
import { css } from '@emotion/react'

interface Props {
  reverse?: boolean
}

export const SContainer = styled.div<Props>`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;

  display: flex;
  flex-direction: column;
  justify-content: center;

  z-index: 1;

  @media ${media.gt('mobile')} {
    ${({ reverse }) =>
      !reverse
        ? css`
            grid-column-start: col-start 1;
            grid-column-end: col-start 5;
          `
        : css`
            grid-column-start: col-end 8;
            grid-column-end: col-end 12;
          `}
  }
`

export const SListWrapper = styled.div<Props>`
  grid-column-start: col-start 1;
  grid-column-end: col-right;

  ${invisibleScrollbars}

  height: ${size(95)};

  @media ${media.gt('tablet')} {
    margin-bottom: ${size(6)};
    height: ${size(93)};

    ${({ reverse }) =>
      !reverse
        ? css`
            grid-column-start: col-start 6;
          `
        : css`
            grid-column-start: col-left;
            grid-column-end: col-start 8;
            grid-row: 1;
          `}
  }

  @media ${media.lte('tablet')} {
    ${({ reverse }) =>
      !reverse
        ? css`
            margin-left: ${size(-4)};
          `
        : css`
            margin-right: ${size(-4)};
          `}
  }
`

export const SList = styled.div<Props>`
  display: flex;
  flex-wrap: nowrap;

  position: relative;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  margin-bottom: 0;

  ${invisibleScrollbars}

  ${({ reverse }) => {
    if (reverse) {
      return css`
        flex-direction: row-reverse;
        mask-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 1) 2%,
          rgba(0, 0, 0, 1)
        );
      `
    }

    return css`
      flex-direction: row;
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1) 2%,
        rgba(0, 0, 0, 1)
      );
    `
  }}
  

  &:after {
    content: '';
    display: block;
    height: 1px;

    min-width: 100vw;
    margin-left: ${size(10)};
  }
`

export const SItemContainer = styled.div<Props>`
  min-width: ${size(80)};
  padding: ${size(4)} ${size(6)};
  padding-bottom: ${size(10)};

  scroll-snap-align: start;

  @media ${media.gt('tablet')} {
    scroll-snap-align: ${({ reverse }) => (!reverse ? 'start' : 'end')};
  }

  @media ${media.lte('tablet')} {
    padding-left: ${size(2)};
    padding-right: ${size(2)};
  }
`
