import { UserReviewFragment } from 'api/contentful/generated/contentful'
import { UserReviewContent } from 'components/userReview/content/UserReviewContent'
import { CarouselControls } from 'driverama-core/components/carousel/controls/CarouselControls'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import {
  TextBody,
  TextHeader,
  TextSubhead
} from 'driverama-core/components/text/Text'
import { layoutGrid } from 'driverama-core/styles/common'
import { useCarousel } from 'driverama-core/utils/hooks'
import { useTranslation } from 'react-i18next'
import {
  SContainer,
  SItemContainer,
  SList,
  SListWrapper
} from './UserReviewsCarouselList.styled'
import { reviewAvatars } from './UserReviewsCarouselList.utils'
import { ReviewsSummaries } from 'components/userReview/summary/ReviewsSummaries'
import { ReactNode } from 'react'

interface Props {
  reviews: UserReviewFragment[]
  reverse?: boolean
  title?: ReactNode
  subtitle?: ReactNode
  description?: ReactNode
}

export function UserReviewsCarouselList(props: Props) {
  const { t } = useTranslation(['core'])
  const { childRefs, containerRef, pageState, handleArrowClick } = useCarousel<
    HTMLDivElement,
    HTMLDivElement
  >()

  return (
    <div css={layoutGrid}>
      <SContainer reverse={props.reverse}>
        <TextSubhead>{props.subtitle ?? t('core:reviews_subhead')}</TextSubhead>

        <Spacer size={3} />

        <TextHeader variant={['h5', 'h4', 'h2']} as="h2">
          {props.title ?? t('core:reviews_title')}
        </TextHeader>

        <Spacer axis="vertical" size={3} />

        <TextBody>{props.description ?? t('core:reviews_subtitle')}</TextBody>

        <ReviewsSummaries />

        <Spacer size={8} />
      </SContainer>

      <SListWrapper reverse={props.reverse}>
        <SList ref={containerRef} reverse={props.reverse}>
          {props.reviews?.map((review, i) => (
            <SItemContainer
              key={i}
              reverse={props.reverse}
              ref={node => {
                if (node) {
                  childRefs.current[i] = node
                  node.dataset.page = `${i + 1}`
                }
              }}
            >
              <UserReviewContent
                {...review}
                avatar={`/images/avatars/${
                  reviewAvatars[i % reviewAvatars.length]
                }.png`}
                isCard
              />
            </SItemContainer>
          ))}
        </SList>
      </SListWrapper>

      <CarouselControls
        items={props.reviews || []}
        pageState={pageState}
        handleArrowClick={handleArrowClick}
        reverse={props.reverse}
      />
    </div>
  )
}
