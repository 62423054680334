import { css } from '@emotion/react'
import { TrustpilotMiniWidget } from 'components/trustpilot/TrustpilotMiniWidget'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { SSumaries } from './ReviewsSummaries.styled'
import { ReviewsSummaryWrapper } from './ReviewsSummaryWrapper.styled'
import { useTrustPilotContext } from 'utils/trustPilotContext'

export function ReviewsSummaries() {
  const { isError, isLoading } = useTrustPilotContext()

  return (
    <ReviewsSummaryWrapper>
      <SSumaries>
        {/* TODO: adblock blocks this widget - better ux in this case? */}
        {!isError && (
          <div
            css={css`
              @media ${media.lte('mobile')} {
                width: ${size(40)};
              }
              @media ${media.gt('SM')} {
                max-width: ${size(40)};
                margin: 0 auto;
              }
            `}
          >
            {isLoading ? (
              <SpinnerCentered variant="x-small" />
            ) : (
              <TrustpilotMiniWidget />
            )}
          </div>
        )}
      </SSumaries>
    </ReviewsSummaryWrapper>
  )
}
